import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'create-cards', loadChildren: () => import('./create-cards/create-cards.module').then(m => m.CreateCardsModule),canActivate: [AuthSuperadminGuard]},
  { path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventModule),canActivate: [AuthSuperadminGuard]},
  { path: 'create-event', loadChildren: () => import('./create-event/create-event.module').then(m => m.CreateEventModule),canActivate: [AuthSuperadminGuard] },
  { path: 'participants-list', loadChildren: () => import('./participants/participants.module').then(m => m.ParticipantsModule),canActivate: [AuthSuperadminGuard]},
  { path: 'create-category', loadChildren: () => import('./create-category/create-category.module').then(m => m.CreateCategoryModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'registered-events', loadChildren: () => import('./all-events/all-events.module').then(m => m.AllEventsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'view-individual', loadChildren: () => import('./view-individual/view-individual.module').then(m => m.ViewIndividualModule),canActivate: [AuthSuperadminGuard] },
  { path: 'view-team', loadChildren: () => import('./view-team/view-team.module').then(m => m.ViewTeamModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'event-closing-panel', loadChildren: () => import('./event-closing-panel/event-closing-panel.module').then(m => m.EventClosingPanelModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-judge', loadChildren: () => import('./create-judge/create-judge.module').then(m => m.CreateJudgeModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'judgement-panel', loadChildren: () => import('./judgement-panel/judgement-panel.module').then(m => m.JudgementPanelModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'event-closing-panel2', loadChildren: () => import('./event-closing-panel2/event-closing-panel2.module').then(m => m.EventClosingPanel2Module) ,canActivate: [AuthSuperadminGuard]},
  { path: 'event-closing-panel3', loadChildren: () => import('./event-closing-panel3/event-closing-panel3.module').then(m => m.EventClosingPanel3Module) ,canActivate: [AuthSuperadminGuard]},
  { path: 'judgement-panel2', loadChildren: () => import('./judgement-panel2/judgement-panel2.module').then(m => m.JudgementPanel2Module) ,canActivate: [AuthSuperadminGuard]},
  { path: 'judgement-panel3', loadChildren: () => import('./judgement-panel3/judgement-panel3.module').then(m => m.JudgementPanel3Module) ,canActivate: [AuthSuperadminGuard]},
  { path: 'judgement-panel4', loadChildren: () => import('./judgement-panel4/judgement-panel4.module').then(m => m.JudgementPanel4Module) ,canActivate: [AuthSuperadminGuard]},
  { path: 'proshow', loadChildren: () => import('./proshow/proshow.module').then(m => m.ProshowModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'merchendise', loadChildren: () => import('./merchendise/merchendise.module').then(m => m.MerchendiseModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-proshow', loadChildren: () => import('./create-proshow/create-proshow.module').then(m => m.CreateProshowModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-proshow-event', loadChildren: () => import('./create-proshow-event/create-proshow-event.module').then(m => m.CreateProshowEventModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-merchendise', loadChildren: () => import('./create-merchendise/create-merchendise.module').then(m => m.CreateMerchendiseModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-tshirt', loadChildren: () => import('./create-tshirt/create-tshirt.module').then(m => m.CreateTshirtModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'success-payment-logs', loadChildren: () => import('./payment-logs/payment-logs.module').then(m => m.PaymentLogsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'proshow-booking-details', loadChildren: () => import('./proshow-booking-details/proshow-booking-details.module').then(m => m.ProshowBookingDetailsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'mahe-merchandise', loadChildren: () => import('./mahe-merchandise/mahe-merchandise.module').then(m => m.MaheMerchandiseModule), canActivate: [AuthSuperadminGuard] },
  { path: 't-shirt-details', loadChildren: () => import('./t-shirt-details/t-shirt-details.module').then(m => m.TShirtDetailsModule), canActivate: [AuthSuperadminGuard] },
  { path: 'failed-payment-logs', loadChildren: () => import('./failed-payment-logs/failed-payment-logs.module').then(m => m.FailedPaymentLogsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'registration-list', loadChildren: () => import('./registration-list/registration-list.module').then(m => m.RegistrationListModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'miscellaneous-payments', loadChildren: () => import('./miscellaneous-payments/miscellaneous-payments.module').then(m => m.MiscellaneousPaymentsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'success', loadChildren: () => import('./success/success.module').then(m => m.SuccessModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'failure', loadChildren: () => import('./failure/failure.module').then(m => m.FailureModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'miscellaneous-user-list', loadChildren: () => import('./miscellaneous-payment-logs/miscellaneous-payment-logs.module').then(m => m.MiscellaneousPaymentLogsModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'scores', loadChildren: () => import('./score/score.module').then(m => m.ScoreModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'cancelled', loadChildren: () => import('./cancel/cancel.module').then(m => m.CancelModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'user-setting', loadChildren: () => import('./user-setting/user-setting.module').then(m => m.UserSettingModule) ,canActivate: [AuthSuperadminGuard]},
  { path: 'create-combo-card', loadChildren: () => import('./combo-card/combo-card.module').then(m => m.ComboCardModule) ,canActivate: [AuthSuperadminGuard]},


  // { path: 'judge-login', loadChildren: () => import('./judge-login/judge-login.module').then(m => m.JudgeLoginModule) ,canActivate: [AuthSuperadminGuard]},

  // { path: 'view1', loadChildren: () => import('./image/image.module').then(m => m.ImageModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'institution-user', loadChildren: () => import('./institute_user/institute_user.module').then(m => m.InstituteUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'roi-user', loadChildren: () => import('./roi_user/roi_user.module').then(m => m.RoiUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'report-user', loadChildren: () => import('./report_user/report_user.module').then(m => m.ReportUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'venue-user', loadChildren: () => import('./venue_user/venue_user.module').then(m => m.VenueUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'food-user', loadChildren: () => import('./food_user/food_user.module').then(m => m.FoodUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'pr-user', loadChildren: () => import('./pr-user/pr-user.module').then(m => m.PrUserModule),canActivate: [AuthSuperadminGuard]},

  // { path: 'view', loadChildren: () => import('./on-postal/on-postal.module').then(m => m.OnPostalModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'refund', loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'seating-arrangement', loadChildren: () => import('./seating_arrangement/seating_arrangement.module').then(m => m.SeatingArrangementModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'settings', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'employee_registration', loadChildren: () => import('./employee-registration/employee-registration.module').then(m => m.EmployeeRegistrationModule),canActivate: [AuthSuperadminGuard]},
  //  { path: 'postal-images', loadChildren: () => import('./postal-images/postal-images.module').then(m => m.PostalImagesModule),canActivate: [AuthSuperadminGuard]},

  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
