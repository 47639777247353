// export const environment = {
//   appName: "MAHE_SUPERADMIN",
//   production: true,


//    apiUrl: 'https://dev-api-techtatva.manipal.edu/',//testing

//   // apiUrl: 'https://api.revelsmit.in/',//dev
//   // apiUrl: 'https://api.revelsmit.in/',
//   oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
//   media_domain: 'https://d163sfb531qyyt.cloudfront.net/techtatva/testing',
//   apiPrefix: 'api/v1',
// };
export const environment = {
  appName: "CONVOCATION_SUPERADMIN",
  production: false,

  //  apiUrl: 'https://api.revelsmit.in/',
  apiUrl: 'https://dev-api.revelsmit.in/',
  // apiUrl: 'http://devapi-convocation.manipal.edu/', //dev
  // apiUrl: 'https://staggingapi.manipalsuperadmin.com/',
  oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
  // media_domain: 'https://dzklmbj651gn4.cloudfront.net/development', //dev
  // media_domain: 'https://d163sfb531qyyt.cloudfront.net/production',
  media_domain: 'https://d163sfb531qyyt.cloudfront.net/revels/testing',
  apiPrefix: 'api/v1',
  secret:'mysecretkeysecretrevels'
}
