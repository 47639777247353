import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class UserService {
 

  constructor(private http: HttpClient) { }
  saveInsuser(institution, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'institution': institution,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      'role_id': role_id,
      'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  setting(slot, event, day, event_time) {
    var formData = {
      'event': slot,
      'day': day,
      'event_slot': event,
      // 'event_time':event_time
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close-create`, formData).pipe(map(user => {
      return user;
    }));
  }

  updatecolumn(event, rows, columndate) {
    var formData = {
      'total_rows': rows,
      'event_slot': event,
      'event': columndate
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/no-seats-create`, formData).pipe(map(user => {
      return user;
    }));
  }

  // columns(event, day, ) {
  //   var formData = {
  //     'event': event,
  //     'day': day,
  //   }
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close-create`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }



  updateInsuser(id, institution, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'institution': institution,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      // 'role_id': role_id,
      // 'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }



  saveFooduser(name, mobile_number, email, role_id, role_type) {
    var formData = {
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      'role_id': role_id,
      'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-venue-users`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateFooduser(id, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      // 'role_id': role_id,
      // 'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/update-venue-users/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getInsuser(page, search, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin?role_type=` + role_type + '&page=' + page + '&search=' + search).pipe(map(user => user));
  }
  getInsuserfilter(page, search, institution, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin?role_type=` + role_type + '&page=' + page + '&search=' + search + '&institution=' + institution).pipe(map(user => user));
  }


  getInstitute(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute`).pipe(map(user => user));
  }

  uploadInsuser(file: File, id: any, type: any) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      formData.append('role_id', id);
      formData.append('role_type', type);
      return this.http
        .post<any>(
          ` ${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-excel`,
          formData
        )
        .pipe(
          map((user) => {
            return user;
          })
        );
    }

  }

  uploadFooduser(file: File, id: any, type: any) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      formData.append('role_id', id);
      formData.append('role_type', type);
      return this.http
        .post<any>(
          ` ${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-excel-venue-users`,
          formData
        )
        .pipe(
          map((user) => {
            return user;
          })
        );
    }
  }

  deleteUser(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/delete-users/` + id).pipe(map(user => {
      return user;
    }));
  }

  deleteAllUser(type: string) {
    var formData = {
      'type': type,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/delete-all-users`, formData).pipe(map(user => {
      return user;
    }));
  }

  getEvents(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events`).pipe(map(user => user));
  }

  getcloselist(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close/` + id).pipe(map(user => user));
  }

  getrowcount(event, id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/no-of-seats/` + id + '?event_slot=' + event).pipe(map(user => user));
  }

  getGateCloseList(id, slotid): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close/` + id + '?event_slot=' + slotid).pipe(map(user => user));
  }

  seatallotment(id, date): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/generate-seats/` + date + '?event_slot=' + id).pipe(map(user => user));
  }

  generateSeat(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/generate-seats/` + id).pipe(map(user => user));
  }

  getVenueuser(page, search, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/venue-food-users?role_type=` + role_type + '&page=' + page + '&search=' + search).pipe(map(user => user));
  }

  //EVENT MANAGEMENT

  createCards(name, type, price) {
    var formData = {
      'card_name': name,
      'card_type': type,
      // 'card_year':year,
      'card_price': price
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  createcomboCards(name, price,proshowid,tshirtid) {
    var formData = {
      'combo_name': name,
      'combo_price': price,
      'proshow_id':proshowid,
      'tshirt_id':tshirtid
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/combo/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateCards(id, name, type, price) {
    var formData = {
      'card_name': name,
      'card_type': type,
      // 'card_year':year,
      'card_price': price
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  updatecomboCards(id, name, price,proshowid,tshirtid) {
    var formData = {
      'combo_name': name,
      'combo_price': price,
      'proshow_id':proshowid,
      'tshirt_id':tshirtid
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/combo/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getCards(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card`).pipe(map(user => user));
  }

  deletecards(_id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/delete/` + _id)
      .pipe(map((user) => {
        return user;
      }) );
  }

  deletecombocards(_id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/combo/delete/` + _id)
      .pipe(map((user) => {
        return user;
      }) );
  }

  getuserCards(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card?page=` + page).pipe(map(user => user));
  }

  getcomboCards(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/combo/list?page=` + page).pipe(map(user => user));
  }

  // createCategory(name) {
  //   var formData = {
  //     'category_name': name
  //   }
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/add`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }
  // //get catagory
  // getCategoryList(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/view`).pipe(map(user => {
  //     return user;
  //   }));
  // }
  // //delete category
  // deleteCategory(id: string) {
  //   return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/delete/` + id).pipe(map(user => {
  //     return user;
  //   }));
  // }
  // //edit category
  // updateCatagory(id, name) {
  //   var formData = {
  //     'category_name': name
  //   }
  //   return this.http
  //     .put<any>(
  //       `${environment.apiUrl}${environment.apiPrefix}/admin/category/update/` + id, formData
  //     )
  //     .pipe(
  //       map((user) => {
  //         return user;
  //       })
  //     );
  // }


  // getEvent(search, event_type, team_type, category_event): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event?search=` + search + '&event_type=' + event_type + '&team_type=' + team_type + '&category_id=' + category_event).pipe(map(user => user));
  // }

  createJudge(card_id, category_id, event_id, judges, judging_criteria) {
    var formData = {
      'card_id': card_id,
      'category_id': category_id,
      'event_id': event_id,
      'judges': JSON.stringify(judges),
      'judgement_criteria': JSON.stringify(judging_criteria)
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateJudges(id,card_id, category_id, event_id, judges, judging_criteria) {
    var formData = {
      'card_id': card_id,
      'category_id': category_id,
      'event_id': event_id,
      'judges': JSON.stringify(judges),
      'judgement_criteria': JSON.stringify(judging_criteria)
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/edit/`+id, formData).pipe(map(user => {
      return user;
    }));
  }

  getJudgeCard(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/dropdown/cards`).pipe(map(user => user));
  }

  // getJudgeCardid(id): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/dropdown/cards?id=`+id).pipe(map(user => user));
  // }

  getJudgeCategory(card_id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/dropdown/category?card_id=` + card_id).pipe(map(user => user));
  }

  getJudgeEvent(category_id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/dropdown/event?category_id=` + category_id).pipe(map(user => user));
  }

  getJudge(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/list`).pipe(map(user => user));
  }

  // getJudge(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/view`).pipe(map(user => user));
  // }

  getJudgePage(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/list?page=` + page).pipe(map(user => user));
  }

  deleteJudge(_id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/delete/` + _id)
      .pipe(map((user) => {
        return user;
      })
      );
  }

  editJudge(_id) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin//judges/view/` + _id)
      .pipe(map((user) => {
        return user;
      })
      );
  }

  createCategory(card_id, name, image) {

    const formData: FormData = new FormData();
    formData.append('card_id', card_id);
    formData.append('category_name', name);
    // formData.append('featured', featured);
    if (image) {
      formData.append('category_image', image, image.name);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  //get catagory
  getCategoryList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/view`).pipe(map(user => {
      return user;
    }));
  }

  getCetegoryDropdown(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/dropdown`).pipe(map(user => {
      return user;
    }));
  }

  getcategoryPage(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/view?page=` + page).pipe(map(user => user));
  }

  searchcategory(search){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/?search=` + search).pipe(map(user => user));
  }
  //delete category
  deleteCategory(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category/delete/` + id).pipe(map(user => {
      return user;
    }));
  }
  //edit category
  updateCatagory(id, name, categoryname, image, file) {
    const formData: FormData = new FormData();
    formData.append('card_id', name);
    formData.append('category_name', categoryname);
    if (file) {
      formData.append('category_image', file, file.name);
    } else {
      formData.append('category_image', image);
    }
    return this.http
      .put<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/category/update/` + id, formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  //cerate event
  crateEvent(card_id, category_id, eventName, eventType, eventTitle, eventDesc, teamType, teamSizeMin, teamSizeMax, eventDate, eventTime, venueName, venueLatitude, venueLongitude, eventAmount, eventJudge) {
    var formData = {
      'card_id': card_id,
      'category_id': category_id,
      'event_name': eventName,
      'event_type': eventType,
      'event_title': eventTitle,
      'event_desc': eventDesc,
      'team_type': teamType,
      'team_size_min': teamSizeMin,
      'team_size_max': teamSizeMax,
      'event_date': eventDate,
      'event_time': eventTime,
      'venue_name': venueName,
      'venue_latitude': venueLatitude,
      'venue_longitude': venueLongitude,
      'event_amount': eventAmount,
      'event_judge': eventJudge,
      // 'event_status': eventStatus
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/add`, formData).pipe(map(user => {
      return user;
    }));
  }
  //get card year
  getCardYear(year): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/card_year?card_year=` + year).pipe(map(user => {
      return user;
    }));
  }
  //get card type
  // getCardType(cardName): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/card_type?card_name=` + cardName).pipe(map(user => {
  //     return user;
  //   }))
  // }
  //get event details
  getEvent(search, page, event_type, team_type, category_event,id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event?search=` + search + `&page=` + page + '&event_type=' + event_type + '&team_type=' + team_type + '&category_id=' + category_event + '&card_id=' +id ).pipe(map(user => user));
  }
  getallEvent(search, page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/result/all-event?search=` + search + `&page=` + page ).pipe(map(user => user));
  }

  getSingleEventResult(page:any,id:any){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/result/single-event?page=` + page + '&event_id=' + id).pipe(map(user => user))
  }
  //delete event
  deleteEvent(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/delete/` + id).pipe(map(user => {
      return user;
    }));
  }

  getCardsEvent(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/card-dropdown`).pipe(map(user => user));
  }

  // getEventinEachCard(id)
  //  {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/card-event-list?card_id=` + id).pipe(map(user => user));

  // }

  getEventinEachCard(id)
  {
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event?card_id=` + id).pipe(map(user => user));
 
   }

  //update event by id
  updateEvent(id, card_id, category_id, eventName, eventType, eventTitle, eventDesc, teamType, teamSizeMin, teamSizeMax, eventDate, eventTime, venueName, venueLatitude, venueLongitude, eventAmount, eventJudge) {
    var formData = {
      'card_id': card_id,
      'category_id': category_id,
      'event_name': eventName,
      'event_type': eventType,
      'event_title': eventTitle,
      'event_desc': eventDesc,
      'team_type': teamType,
      'team_size_min': teamSizeMin,
      'team_size_max': teamSizeMax,
      'event_date': eventDate,
      'event_time': eventTime,
      'venue_name': venueName,
      'venue_latitude': venueLatitude,
      'venue_longitude': venueLongitude,
      'event_amount': eventAmount,
      'event_judge': eventJudge,
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getCategoryDropdown(card_id) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/category-dropdown?card_id=` + card_id).pipe(map(user => user));

  }

    //Event closing panel 2

    getClosedCountEvent() {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/event-count`).pipe(map(user => user));
  
    }
  
    //registered event
    getParticipantsDetails(id,page,search) {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/team-details/` + id+'?page='+page+'&search='+search).pipe(map(user => user));
  
    }

    searchParticipantsDetails(id,search) {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/team-details/` + id + `?search=` + search).pipe(map(user => user));
  
    }

    registeredTeamCount(id){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/registered-team-count/?event_id=` + id).pipe(map(user => user));
    }

    //viewTeamMembers
    viewTeamParticipants(id,team_id){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/participant-details/` + id + `?team_id=` + team_id).pipe(map(user => user));
    }

    //viewIndividualMembers
    viewIndividualParticipant(id,page){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/individual-details?event_id=` + id + '&page=' +page ).pipe(map(user => user));
    }

    getParticipantsDetailsPage(page) {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/team-details?page=` + page).pipe(map(user => user));
  
    }

  //get event by id
  getEventById(id: any) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/view/` + id).pipe(map(user => {
      return user;
    }));
  }

  getCardCount() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/event-count`).pipe(map(user => {
      return user;
    }));
  }

  getParticipantList(user_type,page,search) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/list?user_type=`+ user_type + '&page=' + page+'&search='+search).pipe(map(user => {
      return user;
    }));
  }

  getParticipantCount() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/count`).pipe(map(user => {
      return user;
    }));
  }

  // getBlockListParticipant(id) {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/blocklist/` + id).pipe(map(user => {
  //     return user;
  //   }));
    
    getBlockListParticipant(id,status)
    {
      let apiStatus = status == 1 ? 0 : 1;
       return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/blocklist/` + id, { status: apiStatus }).pipe(map(user => user));
     }

     getActiveParticipant(id,status)
    {
      let apiStatus = status == 0 ? 1 : 0;
       return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/blocklist/` + id, { status: apiStatus }).pipe(map(user => user));
     }

  searchParticipantList(search) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/list?search=` + search).pipe(map(user => {
      return user;
    }));
  }

  getParticipantListPage(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/list?page=` + page).pipe(map(user => user));
  }

  getMaheParticipantList(user_type) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/list?user_type=` + user_type ).pipe(map(user => user));
  }

  getJudgementPanel(page,search) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/index?page=`+page+'&search='+search).pipe(map(user => user));
  }

  getJudgementPanelpage(page){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/index?page=`+page).pipe(map(user => user));
  }

  searchJudgementPanel(search){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/index?search=`+search).pipe(map(user => user));
  }

  teamcount(){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/team-count`).pipe(map(user => user));
  }

  individualcount(){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/team-count`).pipe(map(user => user));
  }

  getpoints(id){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/judges/give-point/`+id).pipe(map(user => user));
  }

  //judgement-panel
  judgementPanel(search,page){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/judges/dashboard?search=`+ search + '&page=' + page).pipe(map(user => user));
  }

  getJudgepoints(id){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/judges/dashboard/give-point/`+id).pipe(map(user => user));
  }

  givepoints(reg_event_id, points) {
    var formData = {
      'reg_event_id': reg_event_id,
      //'judge': judge,
      'points': JSON.stringify(points)
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/judges/dashboard/give-point`, formData).pipe(map(user => {
      return user;
    }));
  }

  // getBlockListParticipant(id,status)
  // {
  //   let apiStatus = status == 1 ? 0 : 1;
  //    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/blocklist/` + id, { status: apiStatus }).pipe(map(user => user));
  //  }

  //  getActiveParticipant(id,status)
  // {
  //   let apiStatus = status == 0 ? 1 : 0;
  //    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/blocklist/` + id, { status: apiStatus }).pipe(map(user => user));
  //  }

   //patch
  closeventStatus(id,status)
  {
     let apiStatus = status == 1 ? 0 : 1;
     return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/status-update/` + id, { status: apiStatus }).pipe(map(user => user));
   }

   openeventStatus(id,status){
    let apiStatus = status == 0 ? 1 : 0;
    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/status-update/` + id, { status: apiStatus }).pipe(map(user => user));
   }



     //reg event list
  registredEventList(search, page, event_type, team_type, category_event, card_id) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/registered-event?search=` + search + `&page=` + page + '&event_type=' + event_type + '&team_type=' + team_type + '&category_id=' + category_event + '&card_id=' + card_id).pipe(map(user => user));
  }
  

  getClosingPanelList(search, page, event_type, team_type, category_event,status) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/view?search=` + search + `&page=` + page + '&event_type=' + event_type + '&team_type=' + team_type + '&category_id=' + category_event + '&status=' + status).pipe(map(user => user));
  }

  countClosingEvents(){
    //let apiStatus = status == 1 ? 0 : 1;
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/view?status=0`).pipe(map(user => user));
  }

  countOpenEvents(){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/view?status=1`).pipe(map(user => user));
  }

  // countOpenEventPage(search, page, event_type, team_type, category_event,status){
  //   let apiStatus = status == 1 ? 1 : 0;
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/view?search=` + search + `&page=` + page + '&event_type=' + event_type + '&team_type=' + team_type + '&category_id=' + category_event + '&status=' + apiStatus).pipe(map(user => user));
  // }

  getSearchByDeligate(id,page)
 {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/participents/` + id + `?page=`+ page).pipe(map(user => user));

  }
  getSearchByDeligateSearch(id,search) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/participents/` + id + `?search=`+ search).pipe(map(user => user));
  }

  teamMembersDetailsinViewTeam(event_id, team_id) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event//participant-details/` + event_id + '?team_id=' + team_id).pipe(map(user => user));

  }

  eventStatusUpdate(id, event_status) {
    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/update-status/` + id, { event_status: event_status }).pipe(map(user => user));

  }

  proshowStatusUpdate(id,status) {
    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/qr-code/status/` + id, { status: status }).pipe(map(user => user));

  }


  geteventclosingpanelindividual(id,page)
  {
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/individual-count/` + id +'?page=' + page ).pipe(map(user => user));
 
   }

  //countOpenEVent
  openEventCount(){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/event-count`).pipe(map(user => user));
  }

  //registered event count
  getRegCardCount(){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/registered-event-count`).pipe(map(user => user));
  }

  //registered event card list
  getRegEventinEachCard(id){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/event/registered-event?card_id=` + id).pipe(map(user => user));
  }

  //event closing team count
  eventClosingTeamCount(id){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/closeEvent/registered-team-count?event_id=` + id).pipe(map(user => user));
  }

  //proshow
  getProshow(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/?page=`+ page).pipe(map(user => user));
  }


  getProshowdetails(search,page,day,status): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/qr-code/list?page=`+ page+'&search='+search+'&proshow_event_day='+day+'&status='+status).pipe(map(user => user));
  }
  getProshowcount(day): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/qr-code/count?proshow_event_day=`+day).pipe(map(user => user));
  }


  getRegistrationlist(search,page,type): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/user/user-list?page=`+ page+'&search='+search+'&user_type='+type).pipe(map(user => user));
  }

  downloaduser(): Observable<any> {
    const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/user/user-download`, { responseType: 'blob' as 'json' }).pipe(map(user => {
        return user
    }));
    return file;
}

downloadmiscellaneoususer(): Observable<any> {
  const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/miscellaneous/miscellaneous-download`, { responseType: 'blob' as 'json' }).pipe(map(user => {
      return user
  }));
  return file;
}

downloadparticipants(): Observable<any> {
  const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/participant/participant-download`, { responseType: 'blob' as 'json' }).pipe(map(user => {
      return user
  }));
  return file;
}


downloadsuccess(): Observable<any> {
  const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/payment/successful-download`, { responseType: 'blob' as 'json' }).pipe(map(user => {
      return user
  }));
  return file;
}

downloadfailure(): Observable<any> {
  const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/payment/failed-download`, { responseType: 'blob' as 'json' }).pipe(map(user => {
      return user
  }));
  return file;
}



  createProshow(name,maheprice,nonmaheprice) {
    var formData = {
      'proshow_name': name,
      'proshow_price_mahe': maheprice,
      'proshow_price_nonmahe':nonmaheprice
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteProshow(id){
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/delete/` + id)
      .pipe(map((user) => {
        return user;
      }));
  }

  updateProshow(id, name,maheprice,nonmaheprice,mahestatus,nonmahestatus) {
    var formData = {
      'proshow_name': name,
      'proshow_price_mahe': maheprice,
      'proshow_price_nonmahe':nonmaheprice,
      'status_mahe':mahestatus,
      'status_nonmahe':nonmahestatus
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  //merchendise
  getMerchendise(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/merchendise/view?page=`+ page).pipe(map(user => user));
  }

  createMerchendise(name) {
    var formData = {
      'merchendise_name': name,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/merchendise/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteMerchendise(id){
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/merchendise/delete/` + id)
      .pipe(map((user) => {
        return user;
      }));
  }

  updateMerchendise(id, name) {
    var formData = {
      'merchendise_name': name
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/merchendise/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  //proshow-event 

  proshowEventAdd(proshow_id,proshow_event_name,proshow_event_artist,proshow_event_day,proshow_event_desc,proshow_event_date,proshow_event_time,proshow_event_location,proshow_event_latitude,proshow_event_longitude,proshow_image){
    // var formData = {
    //   'proshow_id': proshow_id,
    //   'proshow_event_name': proshow_event_name,
    //   'proshow_event_artist': proshow_event_artist,
    //   'proshow_event_day': proshow_event_day,
    //   'proshow_event_desc': proshow_event_desc,
    //   'proshow_event_date': proshow_event_date,
    //   'proshow_event_time': proshow_event_time,
    //   'proshow_event_location': proshow_event_location,
    //   'proshow_event_latitude': proshow_event_latitude,
    //   'proshow_event_longitude': proshow_event_longitude,
    //   'proshow_event_image': proshow_image.name,
    // }
    const formData: FormData = new FormData();
    formData.append('proshow_id', proshow_id);
    formData.append('proshow_event_name', proshow_event_name);
    formData.append('proshow_event_artist', proshow_event_artist);
    formData.append('proshow_event_day', proshow_event_day);
    formData.append('proshow_event_desc', proshow_event_desc);
    formData.append('proshow_event_date', proshow_event_date);
    formData.append('proshow_event_time', proshow_event_time);
    formData.append('proshow_event_location', proshow_event_location);
    formData.append('proshow_event_latitude', proshow_event_latitude);
    formData.append('proshow_event_longitude', proshow_event_longitude);
    if (proshow_image) {
      formData.append('proshow_event_image', proshow_image, proshow_image.name);
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow-event/add`, formData).pipe(map(user => {
      return user;
    }));

  }

  // getProshow(page): Observable<any> {
  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/?page=`+ page).pipe(map(user => user));
  //   }

    getProShowEvent(search,page){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow-event?search=`+ search + '&page=' + page).pipe(map(user => user));
    }

    deleteProShowEvent(id){
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow-event/delete/` + id).pipe(map(user => {
        return user;
      }));
    }

    getProshowEventById(id: any) {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow-event/view/` + id).pipe(map(user => {
        return user;
      }));
    }

    proshowEventUpdate(id,proshow_id,proshow_event_name,proshow_event_artist,proshow_event_day,proshow_event_desc,proshow_event_date,proshow_event_time,proshow_event_location,proshow_event_latitude,proshow_event_longitude,proshow_image){
      // var formData = {
      //   'proshow_id': proshow_id,
      //   'proshow_event_name': proshow_event_name,
      //   'proshow_event_artist': proshow_event_artist,
      //   'proshow_event_day': proshow_event_day,
      //   'proshow_event_desc': proshow_event_desc,
      //   'proshow_event_date': proshow_event_date,
      //   'proshow_event_time': proshow_event_time,
      //   'proshow_event_location': proshow_event_location,
      //   'proshow_event_latitude': proshow_event_latitude,
      //   'proshow_event_longitude': proshow_event_longitude,
      //   'proshow_event_image': proshow_image.name,
      // }
    const formData: FormData = new FormData();
    formData.append('proshow_id', proshow_id);
    formData.append('proshow_event_name', proshow_event_name);
    formData.append('proshow_event_artist', proshow_event_artist);
    formData.append('proshow_event_day', proshow_event_day);
    formData.append('proshow_event_desc', proshow_event_desc);
    formData.append('proshow_event_date', proshow_event_date);
    formData.append('proshow_event_time', proshow_event_time);
    formData.append('proshow_event_location', proshow_event_location);
    formData.append('proshow_event_latitude', proshow_event_latitude);
    formData.append('proshow_event_longitude', proshow_event_longitude);
    if (proshow_image) {
      formData.append('proshow_event_image', proshow_image, proshow_image.name);
    }
      return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow-event/update/`+id, formData).pipe(map(user => {
        return user;
      }));
  
    }

    //Tshirt
    getTshirt(page){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/view?page=` + page).pipe(map(user => user));
    }


    createTshirt(id, price, image:File[]) {
      var i = 0;
      const formData: FormData = new FormData();
      image.map(image => {
        formData.append('tshirt_image', image, image.name);
      });
      formData.append('merchendise_id', id);
      formData.append('tshirt_price', price);
     
  
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/add`, formData).pipe(map(user => {
        return user;
      }));
    }

    updateTshirt(t_id,id, price, image,status) {

      const formData: FormData = new FormData();
      formData.append('merchendise_id', id);
      formData.append('tshirt_price', price);
      if (image) {
        image.map(image => {
          formData.append('tshirt_image', image, image.name);
        });
      }
      formData.append('is_active',status)
  
      return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/update/`+t_id, formData).pipe(map(user => {
        return user;
      }));
    }

    deleteTshirt(id){
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/delete/` + id)
        .pipe(map((user) => {
          return user;
        }));
    }

    //download-excel
    individualexcelsheet(id): Observable<any>{
      const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/event-download/individual?event_id=`+id,{ responseType: 'blob' as 'json' })
      .pipe(map(user => {
          return user
        }));
      return file;
    }

    teamexcelsheet(id): Observable<any>{
      const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/event-download/team?event_id=`+id,{ responseType: 'blob' as 'json' })
      .pipe(map(user => {
          return user
        }));
      return file;
  }

   cardexcelsheet(id): Observable<any>{
    const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/card-download?card_id=`+id,{ responseType: 'blob' as 'json' })
    .pipe(map(user => {
        return user
      }));
    return file;
  }

  cardSportsexcelsheet(id): Observable<any>{
    const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/card/event-download?card_id=`+id,{ responseType: 'blob' as 'json' })
    .pipe(map(user => {
        return user
      }));
    return file;
  }

  tshirtexcelsheet(id): Observable<any>{
    const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/tshirt-download?tshirt_id=`+id,{ responseType: 'blob' as 'json' })
    .pipe(map(user => {
      return user
      }));
    return file;
  }

  proshowexcelsheet(id): Observable<any>{
    const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/proshow/proshow-download?proshow_id=`+id,{ responseType: 'blob' as 'json' })
    .pipe(map(user => {
        return user
      }));
    return file;
  }

  //payment logs
  paymentLogs(page,search){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/payment/logs?page=` + page + '&search=' + search).pipe(map(user => user));
  }
  mpaymentLogs(page,search){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/miscellaneous/payment-list?page=` + page + '&search=' + search).pipe(map(user => user));
  }

  failedpaymentLogs(page,search){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/payment/logs-failed?page=` + page + '&search=' + search).pipe(map(user => user));
  }

  purchasedTshirtDetails(page, search, size) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/purchased-tshirt?page=` + page + '&search=' + search + '&size=' + size).pipe(map(user => user));
  }
  viewTshirt() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/view`).pipe(map(user => user));

  }

  viewTshirtcount() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/view-count`).pipe(map(user => user));

  }



  tshirtSizeUpdate(id, size_id, status) {
    let apiStatus = status == 1 ? 0 : 1;
    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/size-status-update/` + id + '?size_id=' + size_id, { 'size_id': size_id, 'size_status': status }).pipe(map(user => user));
  }

  issuedTshirtUpadte(id, tshirt_info_id,combo, status) {
    let apiStatus = status == 1 ? 0 : 1;
    return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/issued/` + id + '?tshirt_info_id=' + tshirt_info_id, { 'tshirt_info_id': tshirt_info_id, 'issued_status': status ,combo:combo}).pipe(map(user => user));
  }

  getIssuedTshirt(page, search) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/tshirt/issued-tshirts?page=` + page + `&search=` + search).pipe(map(user => user));

  }

  getmaheuserinfo(type, rollno) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/miscellaneous/user-info?user_type=`+type+'&number='+rollno).pipe(map(user => {
      return user;
    }));
  }

  mahemakePayment(type, mobile,name, email,gender,college_id,institution,purpose,amount,institution_id,course,
   course_id,degree,degree_id,address,city,country,pincode,date_of_birth) {
    var formData = {
      'user_type': type,
      'mobile': mobile,
      'full_name': name,
      'email': email,
      'gender': gender,
      'enrollment_number': college_id,
      'institution': institution,
      'institution_id': institution_id,
      'course': course,
      'course_id': course_id,
      'degree': degree,
      'degree_id': degree_id,
      'address': address,
      'city': city,
      'country': country,
      'pincode': pincode,
      'date_of_birth':date_of_birth,
      'purpose':purpose,
      'amount':amount
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/miscellaneous/make-payment`, formData).pipe(map(user => {
      return user;
    }));
  }

  nonmahemakePayment(type, mobile,name, email,gender,college_id,institution,purpose,amount) {
     var formData = {
       'user_type': type,
       'mobile': mobile,
       'full_name': name,
       'email': email,
       'gender': gender,
       'enrollment_number': college_id,
       'institution': institution,
      'purpose':purpose,
      'amount':amount
       // 'event_status': eventStatus
     }
     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/miscellaneous/make-payment`, formData).pipe(map(user => {
       return user;
     }));
   }

   // Sub Admin

  getSubadmin(page:any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/profile/getall-subadmin?page=` + page).pipe(map(user => user));
  }

  createsubadmin(name,email,password,permission) {
    var formData = {
      'username': name,
      'email': email,
      'password':password,
      'permissions':JSON.stringify(permission)
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/profile/sub-admin`, formData).pipe(map(user => {
      return user;
    }));
  }

  updatesubadmin(id,name,email,permission) {
    var formData = {
      'username': name,
      'email': email,
      // 'password':password,
      'permissions':JSON.stringify(permission)
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/profile/update-subadmin/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteSubadmin(id:any){
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/profile/delete-subadmin/` + id).pipe(map(user => user))
  }

  updateSubAdminpassword(id:any,password:any){
    const formData={
       'password':password,
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/profile/update-subadmin-password/` + id, formData).pipe(map(user => {
      return user;
    }));
  }
}
